// TODO: Fill with data from GAMES_SLIDER_CONFIG
export const TOURNAMENTS_CONTENT_GRID_CONFIG = {
  desktop: { gap: 12 },
  mobile: { gap: 6 },
};

// TODO: Move tournaments config to TOURNAMENTS_CONTENT_GRID_CONFIG
export const GAMES_SLIDER_CONFIG = {
  desktop: {
    columns: 6,
    columnsTournament: 3,
    columnsTournamentDetails: 5,
    gap: 12,
    allGamesChunkSize: 84,
    newGamesChunkSize: 12,
    tournamentGamesChunkSize: 6,
    tournamentDetailsGamesChunkSize: 15,
  },
  mobile: {
    columns: 4,
    gap: 6,
    allGamesChunkSize: 80,
    newGamesChunkSize: 8,
  },
  xs: {
    columns: 2,
    allGamesChunkSize: 50,
    tournamentGamesChunkSize: 4,
    newGamesChunkSize: 6,
    gap: 6,
  },
};

export const NEWS_SLIDER_CONFIG = {
  desktop: {
    columns: 3,
    gap: 32,
    chunkSize: 3,
  },
  mobile: {
    columns: 1,
    gap: 16,
    chunkSize: 1,
  },
};

export const NEWS_CONTENT_GRID_CONFIG = {
  desktop: {
    columns: 3,
    gap: 32,
  },
  mobile: {
    columns: 2,
    gap: 16,
  },
  xs: { columns: 1 },
};

// TODO: Refactor all promo pages to avoid using news config instead of promo config
export const PROMO_CONTENT_GRID_CONFIG = {
  desktop: { gap: 32 },
  mobile: { gap: 16 },
};
