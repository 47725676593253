import {
  PLATFORM,
  GAME_CATEGORIES,
  GAME_PROVIDERS,
} from '~/constants/general';
import { GAMES_SLIDER_CONFIG } from '~/constants/contentItemsGridConfigs';
import { useGamesStore } from '~/stores/games';
import type { GameFilterParams } from '~/types/general/game';

/**
 * A composable function to handle fetching games on server-side rendering (SSR).
 * @param {string} gameType - The type of game to be fetched (e.g. 'all', 'new').
 * @param {number} [newLimit] - Optional. The number of games to fetch. Defaults based on the device type.
 * @returns {Object} Functions and reactive properties to manage the game fetching process.
 */
export const useFetchGamesSSR = (gameType: string, newLimit?: number) => {
  const { $eventEmitter } = useNuxtApp();
  const { t } = useI18n();
  const gamesStore = useGamesStore();
  const { getGameProviders } = storeToRefs(gamesStore);
  const { fetchGamesByTypeForSSR } = gamesStore;
  const route = useRoute();

  // Reactive object to store the fetch parameters for games
  const fetchGamesParamsData = ref({
    gameCategory: GAME_CATEGORIES.all,
    gameProvider: GAME_PROVIDERS.all,
    offset: 0,
    limit: 0,
    platform: '',
    optionalParams: {
      search: '',
      buyBonusFeature: false,
      gamesInTournamentOnly: false,
      gridSlotsFeature: false,
      label: '',
      megawaysFeature: false,
      minBetDirection: '',
    },
  });

  const tab = ref(GAME_CATEGORIES.all);
  const isMobileMode = inject<Ref<boolean>>('isMobileMode');
  const isXsDisplay = inject<Ref<boolean>>('isXsDisplay');

  /**
   * Computed property to determine the limit of games to fetch based on the device type.
   */
  const limit = computed(() =>
    isXsDisplay?.value
      ? GAMES_SLIDER_CONFIG.xs.allGamesChunkSize
      : isMobileMode?.value
        ? GAMES_SLIDER_CONFIG.mobile.allGamesChunkSize
        : GAMES_SLIDER_CONFIG.desktop.allGamesChunkSize,
  );

  /**
   * Computed property to format the game providers list for display.
   */
  const formattedProviders = computed(() => {
    return [
      {
        label: t('general_all'),
        publishers: [GAME_PROVIDERS.all],
        urlMapping: GAME_PROVIDERS.all.toLowerCase(),
      },
      ...getGameProviders.value,
    ];
  });

  /**
   * Utility function to convert a hyphenated string to camelCase.
   * @param {string} str - The input string.
   * @returns {string} The camelCased string.
   */
  const hyphenToCamelCase = (str: string) => {
    if (!str.includes('-')) {
      return str.toUpperCase();
    } else {
      return str.replace(/-[a-z]/g, (match) => match[1].toUpperCase());
    }
  };

  /**
   * Sets the parameters required to fetch games based on the route and filter values.
   */
  const setParamsToFetchGamesParams = () => {
    const filterParams: { [key: string]: string | boolean } = {};

    if (route.params.params?.length) {
      (route.params.params as string[]).forEach((param) => {
        const [key, value] = param.includes('=')
          ? param.split('=')
          : [param, 'true'];
        const formattedKey = key === 'search'
          ? key
          : hyphenToCamelCase(key);
        const formattedValue =
          key === 'search' || value === 'true'
            ? value
            : hyphenToCamelCase(value);

        filterParams[formattedKey] = formattedValue;
      });
    }

    const setParam = (param: string, defaultValue: string = '') =>
      filterParams[param]
        ? String(filterParams[param])
        : defaultValue;

    const currentProvider = formattedProviders.value.find(
      (provider) => provider.urlMapping === route.params.gameProvider,
    );

    fetchGamesParamsData.value.gameProvider = setParam(
      'gameProvider',
      currentProvider?.publishers[0],
    );
    fetchGamesParamsData.value.gameCategory = setParam(
      'gameCategory',
      String(route.params.gameCategory).replaceAll('-', '_').toUpperCase(),
    );
    fetchGamesParamsData.value.optionalParams.search = setParam('search');
    fetchGamesParamsData.value.optionalParams.buyBonusFeature =
      filterParams.buyBonusFeature === 'true';
    fetchGamesParamsData.value.optionalParams.gamesInTournamentOnly =
      filterParams.gamesInTournamentOnly === 'true';
    fetchGamesParamsData.value.optionalParams.gridSlotsFeature =
      filterParams.gridSlotsFeature === 'true';
    fetchGamesParamsData.value.optionalParams.label = setParam('label');
    fetchGamesParamsData.value.optionalParams.megawaysFeature =
      filterParams.megawaysFeature === 'true';
    fetchGamesParamsData.value.optionalParams.minBetDirection =
      setParam('minBetDirection');

    tab.value = fetchGamesParamsData.value.gameCategory;
  };

  /**
   * Asynchronously loads games based on the current parameters.
   */
  const loadGamesSSR = async () => {
    const gamesParams = {
      ...fetchGamesParamsData.value.optionalParams,
      search: encodeURIComponent(fetchGamesParamsData.value.optionalParams.search),
      gameCategory: fetchGamesParamsData.value.gameCategory,
      gameProvider: fetchGamesParamsData.value.gameProvider,
      offset: fetchGamesParamsData.value.offset,
      limit: newLimit || limit.value,
      platform: PLATFORM.desktop,
    };

    // Remove falsy values from the parameters
    Object.keys(gamesParams).forEach((key) => {
      const value = gamesParams[key as keyof GameFilterParams] as
        | string
        | boolean
        | null
        | undefined;

      if ([false, '', null, undefined].includes(value)) {
        delete gamesParams[key as keyof GameFilterParams];
      }
    });

    await fetchGamesByTypeForSSR(gamesParams, gameType);
    $eventEmitter.emit(
      'get-filter-params',
      gamesParams,
    );
  };

  return {
    setParamsToFetchGamesParams,
    loadGamesSSR,
    limit,
    fetchGamesParamsData,
    tab,
  };
};
